export {
    formatDate,
    formatBool,
    formatBoolRev,
    formatUser,
    formatSubscription
};

function formatDate(dateString) {
    let date;
    try {
        date = new Date(dateString);
        return new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: 'short' }).format(date);
    } catch (error) {
        return '-';
    }
};

function formatBool(bool) {
    return bool ? 'ja' : 'nein';
};
function formatBoolRev(bool) {
    return !bool ? 'ja' : 'nein';
};

function formatUser(user) {
    return user ? user.email : '-';
};

function formatSubscription(subs){
    return subs ? subs.name : '-';
}
