/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import { createApp, defineCustomElement } from 'vue';
import WordListIndex from './components/WordListIndex.vue'
import WordList from './components/WordList.vue'
import WordIndex from './components/WordIndex.vue'
import LogEntryIndex from './components/LogEntryIndex.vue'

var StudyForm = require('./StudyForm').StudyForm;
var DepartmentOptions = require('./UserForm').DepartmentOptions;
var StatisticsDashboard = require('./statistics/aggregatedChart').Dashboard;
var ScatterChart = require('./statistics/scatterChart').Dashboard;
var SessionChart = require('./statistics/sessionChart').Dashboard;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const vueRoots = [
    {
        selector: "#app-root-word-list",
        component: WordList
    }, {
        selector: "#app-root-word-list-index",
        component: WordListIndex
    }, {
        selector: "#app-root-word-index",
        component: WordIndex
    }, {
        selector: "#app-root-log-entry-index",
        component: LogEntryIndex
    }
]

// const wordListIndexApp = createApp(WordListIndex).mount('#app-root-word-list-index');

vueRoots.forEach((root) => {
    const elem = document.querySelector(root.selector);
    if (elem) {
        let app = createApp(
            root.component//,
            // { ...elem.dataset }
        );
        app.provide('env', { ...elem.dataset });
        app.mount(root.selector);
    }
})
// const wordListApp = createApp(
//     WordList,
//     { wordList: 'test' }
// ).mount('#app-root-word-list');
// console.log({ ...document.querySelector("#app-root-word-list").dataset });

// app.provide('axios', window.axios)
// customElements.define('word-list-index', WordListIndex)
// const WordListElement = defineCustomElement(WordList);
// customElements.define('word-list', WordListElement)

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//  const app = new Vue({
//     el: '#app',
// });


// WYSIWYG FORM FIELD
// Quick and Dirty Wysiwyg form field
$('.wysiwyg-italic-button').on('click', () => {
    document.execCommand('italic', false, '');
})
$('.wysiwyg-bold-button').on('click', () => {
    document.execCommand('bold', false, '');
})

$('form').on('submit', (event) => {
    const wysiwygFields = $(event.target).find('.wysiwyg-field');
    if (wysiwygFields.length) {
        wysiwygFields.each((i, field) => {
            const $field = $(field);
            const $hiddenField = $('<input/>')
            $hiddenField.attr({
                type: 'hidden',
                name: $field.attr('name'),
                value: $field.html()
            })
            $(event.target).append($hiddenField);
        })
    }
    return true; // continue submitting of the form
});
// END OF WYSIWYG FORM FIELD

new window.clipboard('#copy-pw-link');
new window.clipboard('.clipboard-copy');

$('.js-back-button').on('click', () => {
    window.history.back();
    return false;
})

// USER FORM
if ($('#user-form-department-select').length && $('#user-form-subscription-select')) {
    var departmentOptions = new DepartmentOptions($('#user-form-department-select'), $('#user-form-subscription-select'));
}
// END OF USER FORM

// STUDY FORM
if ($('#study-test').length) {

    var allOptions = JSON.parse($('#study-options-json').html());

    var options = [
        {
            name: 'value',
            title: 'Wert',
            options: allOptions.value
        },
        {
            name: 'cluster',
            title: 'Cluster',
            options: allOptions.cluster
        },
        {
            name: 'audience',
            title: 'Zielgruppe',
            options: allOptions.audience
        },
        {
            name: 'strategy',
            title: 'Strategisches Ziel',
            options: allOptions.strategy
        },
        {
            name: 'medium',
            title: 'Medium',
            options: allOptions.medium
        },
        {
            name: 'department',
            title: 'Abteilung',
            options: allOptions.department
        },
        {
            name: 'text_session_position',
            title: 'Textsession Position',
            options: allOptions.text_session_position
        },
        {
            name: 'subscription',
            title: 'Mandant',
            options: allOptions.subscription
        }
    ];

    var study = new StudyForm(
        $('#study-test')[0],
        'study-test-1',
        options,
        true,
        'eindeutiger Name'
    );


    $('#study-preset-clear-btn').on('click', () => {
        study.clear();
    });
    $('.study-preset-btn').on('click', (event) => {
        study.clear();
        study.useJSON($($(event.target).attr('target')).html());
    });

    $('#study-add-number').on('click', () => {
        study.addElement();
    })
    $('#study-show-json').on('click', () => {
        $('#study-json-string').html(study.getJSON());
    })
    $('#study-json-form').on('submit', () => {
        $('#study-json-string').html(study.getJSON());
        return true;
    });
}
// END OF STUDY FORM


$('.ajax-action-link').on('click', (event) => {
    const $target = $(event.target);
    const url = $target.attr('href');
    const pendingText = $target.attr('pending-text');
    const successText = $target.attr('success-text');
    const failedText = $target.attr('failed-text');
    const $responseElem = $($target.attr('response-elem'));
    $responseElem.addClass('mb-4 d-block');
    $responseElem.html(pendingText);
    $responseElem.addClass('text-secondary');
    $responseElem.removeClass('text-success');
    $responseElem.removeClass('text-danger');
    $.get(url, (data) => {
        console.log(data)
        if (data.success) {
            $responseElem.html(successText);
            $responseElem.addClass('text-success');
            $responseElem.removeClass('text-secondary');
            $responseElem.removeClass('text-danger');
        } else {
            $responseElem.html(failedText);
            $responseElem.addClass('text-danger');
            $responseElem.removeClass('text-secondary');
            $responseElem.removeClass('text-success');
        }
    });
    console.log('sende')
    return false;
});

if ($('#global-statistics-dashboard').length) {
    let dataJSON = '[{"label":"","data":{"":{"avg":0}}}]';//$('#dasboard-data').html();
    var dashboard = new StatisticsDashboard(
        document.getElementById('global-statistics-dashboard'),
        document.getElementById('global-statistics-dashboard2'),
        dataJSON);

    var allOptions = JSON.parse($('#study-options-json').html());

    const startDateInput = $('input[name=start_date]')[0];
    const endDateInput = $('input[name=end_date]')[0];
    let someDate = new Date();
    endDateInput.value = someDate.toISOString().substring(0, 10);
    startDateInput.value = (new Date(someDate.setMonth(new Date(someDate).getMonth() - 6))).toISOString().substring(0, 10);
    var allOptions = JSON.parse($('#study-options-json').html());

    var options = [
        {
            name: 'value',
            title: 'Wert',
            options: allOptions.value
        },
        {
            name: 'strategy',
            title: 'Strategisches Ziel',
            options: allOptions.strategy
        },
        {
            name: 'text_session_position',
            title: 'Textsession Position',
            options: allOptions.text_session_position
        },
        {
            name: 'subscription',
            title: 'Mandant',
            options: allOptions.subscription
        }
    ];

    var datasetForm = new StudyForm(
        $('#dasboard-datasets')[0],
        'dashboard-datasets-1',
        options,
        false,
        'eindeutiger Name',
        'dashboard-dataset-form row'
    );

    $('#statistics-add-value').on('click', () => {
        datasetForm.addElement();
    });

    $('#statistics-show-form').on('submit', (event) => {
        const formValues = datasetForm.getValues();
        const requestJSON = JSON.stringify({
            "start_date": startDateInput.value,
            "end_date": endDateInput.value,
            "split_mode": $('select[name="split_mode"]').val(),
            "numbers": formValues
        })
        $(event.target).find('textarea')[0].innerHTML = requestJSON;
        $('#dasboard-data').html(requestJSON);
        $.ajax({
            type: 'POST',
            url: $(event.target).attr("action"),
            data: $(event.target).serialize(),
            success: function (response) {
                dashboard.setRawData(response);
            },
        });
        return false;
    });
}

if ($('#statistic-session-progression-chart').length) {
    let dataJSON = document.getElementById('statistic-text-session-data').innerHTML;
    let activeValues = [];
    var chart = new SessionChart(
        document.getElementById('statistic-session-progression-chart'),
        'line',
        dataJSON,
    );
    $('.form-check-input').on('input', (event) => {
        activeValues = [];
        $('.form-check-input').each((i, input) => {
            if (input.checked) {
                activeValues.push(input.value);
            }
        });
        chart.setVisibleValues(activeValues);
    })
}

// $('#copy-pw-link').click(function(){
//     var copyText = $('#copy-pw-link');

//   /* Select the text field */
//   copyText.select();
//   copyText.setSelectionRange(0, 99999); /*For mobile devices*/

//   /* Copy the text inside the text field */
//   document.execCommand("copy");
// })






var analyseLinks = $('.reference-text-analyse.active');
analyseLinks.on('click', (event) => {
    var link = event.target;
    analyzeLinkClick(link, () => { });
    return false;
});

function analyzeLinkClick(link, callback) {
    if (link.classList.contains('active')) {
        link.classList.remove('active');
        link.classList.add('disabled');
        link.innerHTML = 'Analysiere...';
        $.get(link.getAttribute('href')).always((data) => {
            console.log(data)
            if (data.updated) {
                link.innerHTML = 'Analysiert';
            } else {
                link.innerHTML = 'Fehler';
            }
            callback(data);
        });
    }
}

$('#reference-text-analyse-all').on('click', (event) => {
    var links = $('.reference-text-analyse.active');
    analyseNextLinkRec(0);
    function analyseNextLinkRec(i) {
        if (links.length > i) {
            analyzeLinkClick(links[i], () => {
                analyseNextLinkRec(i + 1)
            }
            );
        }
    }
});




// Reference Texts Chart
if ($('#reference-text-chart-page').length) {
    let dataJSON = '[]';//$('#dasboard-data').html();
    var dashboard = new ScatterChart(
        document.getElementById('reference-text-chart'),
        dataJSON
    );
    updateDimensions();
    $('select[name="dimension-x"], select[name="dimension-y"]').on('change', (event) => {
        updateDimensions();
    })

    function updateDimensions() {
        let x = $('select[name="dimension-x"]').val();
        let y = $('select[name="dimension-y"]').val();
        dashboard.setProperties(
            x,
            y,
            $('select[name="dimension-x"] option[value="' + x + '"]').html(),
            $('select[name="dimension-y"] option[value="' + y + '"]').html()
        );
    }

    var allOptions = JSON.parse($('#study-options-json').html());

    var options = [
        {
            name: 'source_name',
            title: 'Quelle',
            options: allOptions.source_name
        },
        {
            name: 'source_type',
            title: 'Cluster',
            options: allOptions.source_type
        },
        {
            name: 'category_name',
            title: 'Kategorie',
            options: allOptions.category_name
        }
    ];

    var datasetForm = new StudyForm(
        $('#text-selections')[0],
        'text-selections-1',
        options,
        false,
        'eindeutiger Name',
        'dashboard-dataset-form row'
    );

    $('#statistics-add-value').on('click', () => {
        datasetForm.addElement();
    });

    $('#statistics-show-form').on('submit', (event) => {
        const formValues = datasetForm.getValues();
        const requestJSON = JSON.stringify({
            "selections": formValues
        })
        $(event.target).find('textarea')[0].innerHTML = requestJSON;
        $('#dasboard-data').html(requestJSON);
        $.ajax({
            type: 'POST',
            url: $(event.target).attr("action"),
            data: $(event.target).serialize(),
            success: function (response) {
                console.log(response);
                dashboard.setRawData(response.data);
            },
        });
        return false;
    });
}
