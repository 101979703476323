<script setup>
import { onMounted, ref, inject, watch } from 'vue';
import ErrorDisplay from '../elements/ErrorDisplay.vue';
import ToggleSwitch from '../elements/ToggleSwitch.vue';
import axios from 'axios';

const props = defineProps({
    entry: {
        type: Object,
    },
    wordList: {
        type: Object,
    },
    // errors: {
    //     type: Object
    // },
    // loading: {
    //     type: Number,
    //     default: 0
    // }
});

// const wordListsWithAI = ['gender-thesaurus'];

const showAlephInfo = ref(false);
const showAlephResponse = ref(false);
const alephInfoBtn = ref(null);
const alephInfoCollapse = ref(null);
const alephResponseCollapse = ref(null);

let errors = ref(null);

const aiSuggestionsCorrect = ref(null);
const aiSuggestionsWrong = ref(null);
// let suggestionsForWrong = [];
// let suggestionsForCorrect = [];
const loading = ref(false);
let lastEntryId = null;
// const changedIds = [];

onMounted(() => {
    $(alephInfoCollapse.value).on('shown.bs.collapse', () => {
        showAlephInfo.value = true;
    })
    $(alephInfoCollapse.value).on('hidden.bs.collapse', () => {
        showAlephInfo.value = false;
    })
    $(alephResponseCollapse.value).on('shown.bs.collapse', () => {
        showAlephResponse.value = true;
    })
    $(alephResponseCollapse.value).on('hidden.bs.collapse', () => {
        showAlephResponse.value = false;
    })
    watch(() => props.entry, (val) => {
        console.log(val);
        console.log(props.wordList);
        if (lastEntryId != val.id) {
            lastEntryId = val.id;
            onAlephInfoHide();
            onDisableAI();
        }
    });
})

const env = inject('env', {
    wordList: null,
    toolOptions: 'default'
});

const emit = defineEmits(['storeExpression']);

// const flexValues = ['m', 'f', 'gm', 'pm', 'pf', 'pma'];

// const flexNames = {
//     'm': 'männlich',
//     'f': 'weiblich',
//     'gm': 'genitiv',
//     'pm': 'plural männlich',
//     'pf': 'plural weiblich',
//     'pma': 'akkusativ plural'
// };

function onAlephInfoClick() {
    showAlephInfo.value = !showAlephInfo.value;
    $(alephInfoCollapse.value).collapse('toggle');
    alephInfoBtn.value.blur();
}

function onAlephInfoHide() {
    showAlephInfo.value = false;
    $(alephInfoCollapse.value).collapse('hide');
    alephInfoBtn.value.blur();
}

function onEnableAI() {
    showAlephResponse.value = true;
    sendRequest()
    $(alephResponseCollapse.value).collapse('show');

}
function onDisableAI() {
    showAlephResponse.value = false;
    aiSuggestionsCorrect.value = null;
    aiSuggestionsWrong.value = null;
    $(alephResponseCollapse.value).collapse('hide');
}

function processAIResponse(inputString) {
    //     const inputString = `
    // Falsche Ausdrücke:
    // - Krankenschwestern

    // Korrekte Ausdrücke:
    // - Pflegefachkraft
    // - Pflegeperson
    // - Krankenpfleger
    // - Pfleger
    // - Pflegekraft
    // `;

    const lines = inputString.split('\n'); // Split the input string by new lines
    aiSuggestionsCorrect.value = [];
    aiSuggestionsWrong.value = [];

    let priority = 0;

    for (const line of lines) {
        if (line.includes('Falsche Ausdrücke:')) {
            priority = 0;
        } else if (line.includes('Korrekte Ausdrücke:')) {
            priority = 1;
        } else if (line.includes('-')) {
            const expression = line.replace('-', '').trim();
            if (expressionExists(expression) || expression == '') {
                continue;
            }
            if (priority == 0) {
                aiSuggestionsWrong.value.push({
                    word: expression,
                    checked: false,
                    priority: priority
                });
            } else {
                aiSuggestionsCorrect.value.push({
                    word: expression,
                    checked: false,
                    priority: priority
                });
            }
        }
    }

    console.log('False Expressions:', aiSuggestionsWrong.value);
    console.log('Correct Expressions:', aiSuggestionsCorrect.value);
}

function expressionExists(string) {
    for (let exp of props.entry.expressions) {
        if (exp.full_expression == string) {
            return true;
        }
    }
    return false;
}

function getExistiongExpressionString(priority) {
    const existingExpressions = [];
    for (let exp of props.entry.expressions) {
        if (exp.priority == priority) {
            existingExpressions.push(exp.full_expression);
        }
    }
    return existingExpressions.join(',');
}

function sendRequest() {
    loading.value = true;
    console.log(props.entry.expressions);
    const formData = {
        wrong: getExistiongExpressionString(0),
        correct: getExistiongExpressionString(1),
        list_name: props.wordList.title,
    };

    axios.post('/ajax/aiPrompts/wordListSuggestions', formData, {
    })
        .then((res) => {
            loading.value = false;
            console.log(res.data);
            processAIResponse(res.data.trim());
        })
        .catch(function (error) {
            loading.value = false;
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function addAISuggestions(){
    addAISuggestionsRec(aiSuggestionsWrong.value, () => {
        addAISuggestionsRec(aiSuggestionsCorrect.value, () => {
            // onDisableAI();
        });
    });
}

function addAISuggestionsRec(aiSuggestions, finishedCallback) {

    if (aiSuggestions[0]) {
        if (aiSuggestions[0].checked) {
            emit('storeExpression', props.entry.id, aiSuggestions[0].word, aiSuggestions[0].priority)
            aiSuggestions.shift();
            window.setTimeout(addAISuggestions, 500);
        } else {
            aiSuggestions.shift();
            addAISuggestions();
        }
    } else {
        finishedCallback();
    }
}



function hasMoreSuggestions() {
    return aiSuggestionsCorrect.value && aiSuggestionsCorrect.value.length
        || aiSuggestionsWrong.value && aiSuggestionsWrong.value.length;
}

// function useAI() {
//     return env.useAi && wordListsWithAI.indexOf(props.wordList.name) != -1;
// }

</script>

<template>
    <div>
        AI Vorschläge für Varianten
    </div>
    <ToggleSwitch :model="showAlephResponse" @enable="onEnableAI" @disable="onDisableAI"></ToggleSwitch>
    <button ref="alephInfoBtn" type="button" class="btn btn-sm ml-2"
        :class="{ 'btn-info': showAlephInfo, 'btn-outline-info': !showAlephInfo }" @click="onAlephInfoClick">
        <i class="bi bi-info-lg"></i>
    </button>
    <div>
        <div ref="alephInfoCollapse" class="collapse">
            <div class="card card-body mt-2">
                Datenverbindung zum externen Server wird aufgebaut.
            </div>
        </div>
        <div ref="alephResponseCollapse" class="collapse">
            <div class="card card-body mt-2">
                <span v-if="loading">laden...</span>
                <span v-if="!hasMoreSuggestions() && !loading">Keine weiteren Vorschläge
                    gefunden</span>
                <div v-if="hasMoreSuggestions()">
                    <b v-if="aiSuggestionsWrong.length">Falsche Ausdrücke</b>
                    <div class="row" v-for="(sugg) of aiSuggestionsWrong">
                        <div class="col-4 pt-1">{{ sugg.word }}</div>
                        <div class="col-5">
                            <input type="checkbox" v-model="sugg.checked" />
                        </div>
                    </div>
                    <b v-if="aiSuggestionsCorrect.length" class="d-block mt-4">Richtige Ausdrücke</b>
                    <div class="row" v-for="(sugg) of aiSuggestionsCorrect">
                        <div class="col-4 pt-1">{{ sugg.word }}</div>
                        <div class="col-5">
                            <input type="checkbox" v-model="sugg.checked" />
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-7 pt-1"></div>
                        <div class="col-5">
                            <button class="btn btn-sm btn-outline-primary" :disabled="loading" type="button"
                                @click="addAISuggestions">übernehmen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <button class="btn btn-sm btn-outline-primary" :disabled="loading" type="button"
                                    @click="() => { if (!loading) { emit('storeExpression', entry.id, sugg, 0) } }">hinzufügen</button> -->
    <hr />
    <ErrorDisplay :errors="errors"></ErrorDisplay>
</template>
